<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">نام سایت</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="6" md="6" lg="8" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            خوش آمدید 👋 !
          </b-card-title>
          <b-card-text class="mb-2">
            لطفا وارد حساب کاربری خود شوید و ماجراجویی را شروع کنید
          </b-card-text>

          <!-- form -->
          <validation-observer
            class="d-none"
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="ایمیل" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="وارد کنید"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">رمز عبور</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>رمز خود را فراموش کرده اید ؟</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="وارد کنید"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <div>
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-primary d-inline-block"
                    name="check-button"
                    switch
                  />
                  <b-card-text class="mb-0 d-inline-block"
                    >مرا بخاطر بسپار</b-card-text
                  >
                </div>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                ورود
              </b-button>
            </b-form>
          </validation-observer>

          <b-button type="button" @click="login" variant="primary" block>
            ورود سازمان
          </b-button>
          <b-button type="button" @click="driver" variant="primary" block>
            ورود راننده
          </b-button>

          <!--<b-card-text class="text-center mt-2">
            <span>میخواهید ثبت نام کنید ؟</span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;ثبت نام و عضویت</span>
            </b-link>
          </b-card-text>-->

          <!-- divider -->
          <!--<div class="divider my-2">
            <div class="divider-text">or</div>
          </div>-->

          <!-- social buttons -->
          <!--<div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>-->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { localize } from "vee-validate";
localize({
  en: {
    messages: {
      required: "لطفا موارد اجباری را کامل کنید",
      email: "لطفا یک ایمیل صحیح وارد نمایید",
      invalid: "ایمیل یا رمزعبور صحیح نمیباشد",
    },
  },
});

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "checked",
      password: "admin",
      userEmail: "admin@demo.com",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      // this.$refs.loginForm.validate().then((success) => {
      // if (success) {
      useJwt
        .login({
          email: this.userEmail,
          password: this.password,
        })
        .then((response) => {
          const { userData } = response.data;
          useJwt.setToken(response.data.accessToken);
          useJwt.setRefreshToken(response.data.refreshToken);
          localStorage.setItem("userData", JSON.stringify(userData));
          this.$ability.update(userData.ability);

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          this.$store.commit(
            "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
            userData.extras.eCommerceCartItemsCount
          );

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          this.$router
            .replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `خوش آمدید ${userData.fullName || userData.username}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `شما با موفقیت وارد شدید ${userData.username}. حالا میتوانید شروع کنید!`,
                },
              });
            });
        })
        .catch((error) => {
          this.$refs.loginForm.setErrors(error.response.data.error);
        });
      // }
      // });
    },

    driver() {
      this.userEmail = "client@demo.com";
      this.password = "client";
      this.login();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
